import CallButton from "./buttons/call-button";
import { useStore } from "@nanostores/react";
import { accessTokenStore } from "@root/framework/stores/app-store";
import { userPersonalDataStore, userRootDataStore } from "@root/framework/stores/user-store";

const UserCallerForm = (props: { areaId: string, userName: string, areaNumber: string }) => {

    const $userRootDataStore = useStore(userRootDataStore);
    const $userPersonalDataStore = useStore(userPersonalDataStore);
    const $accessTokenStore = useStore(accessTokenStore);

    return (
        <form action={`/api/calls`} method="post">
            <input type="hidden" name="area-number" value={props.areaId} />
            <input type="hidden" name="from-name" value={$userPersonalDataStore?.fullName || "-"} />
            <input type="hidden" name="from-picture" value={$userRootDataStore?.picture || "-"} />
            <input type="hidden" name="access-token" value={$accessTokenStore} />
            <div className="flex flex-row w-full items-center p-2 border-b border-[#D1D0D0]">
                <span className="basis-3/5">{props.userName}</span>
                <span className="basis-1/5">{props.areaNumber || "-"}</span>
                <div className="basis-1/5">
                    <CallButton />
                </div>
            </div>
        </form>
    )
}

export default UserCallerForm;